import React from 'react';
import ComingSoon from './ComingSoon';

const HomePage = () => (
  <>
    <ComingSoon />
  </>
);

export default HomePage;
